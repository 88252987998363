import { defineStore } from 'pinia'
import { DirectusModule } from '~/types/enums'
import type { Container } from '~/types/types'

export const useContainerStore = defineStore('container-store', () => {
  const containers = ref<Container[] | null>(null)

  async function getContainerData() {
    if (containers.value !== null) return

    try {
      const { $directus, $readItems } = useNuxtApp()

      const { data: containerResponse } = await useAsyncData(DirectusModule.Container, () => {
        return $directus.request($readItems(DirectusModule.Container as any, {}))
      })

      if (!containerResponse.value)
        throw createError({
          status: 404,
          statusText: 'Page Not Found',
        })

      containers.value = containerResponse.value as Container[]
    } catch (error) {
      console.error('Error fetching container data: ', error)
    }
  }

  function getContainerById(id: number) {
    if (!containers.value) return null

    return containers.value.find(container => container.id === id)
  }

  return {
    containers,
    getContainerData,
    getContainerById,
  }
})
